export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // Add classes for main menu with CPT
    document.addEventListener("DOMContentLoaded", function () {
      // Verificar si la etiqueta body contiene la clase "miClase"
      if (document.body.classList.contains("single-post")) {
          // Obtener el elemento que deseas modificar
          const elemento = document.getElementById("actualidadMegaMenu");
  
          // Agregar una clase al elemento
          elemento.parentNode.classList.add("current-menu-ancestor");
      }

      // Verificar si la etiqueta body contiene la clase "miClase"
      if (document.body.classList.contains("single-evento")) {
        // Obtener el elemento que deseas modificar
        const elemento = document.getElementById("actualidadMegaMenu");

        // Agregar una clase al elemento
        elemento.parentNode.classList.add("current-menu-ancestor");
      }

      // Verificar si la etiqueta body contiene la clase "miClase"
      if (document.body.classList.contains("single-descargable")) {
        // Obtener el elemento que deseas modificar
        const elemento = document.getElementById("actualidadMegaMenu");

        // Agregar una clase al elemento
        elemento.parentNode.classList.add("current-menu-ancestor");
      }

      // Verificar si la etiqueta body contiene la clase "miClase"
      if (document.body.classList.contains("single-equipo")) {
        // Obtener el elemento que deseas modificar
        const elemento = document.getElementById("equipoMegaMenu");

        // Agregar una clase al elemento
        elemento.parentNode.classList.add("current-menu-ancestor");
      }

      // Verificar si la etiqueta body contiene la clase "miClase"
      if (document.body.classList.contains("single-podcast")) {
        // Obtener el elemento que deseas modificar
        const elemento = document.getElementById("actualidadMegaMenu");

        // Agregar una clase al elemento
        elemento.parentNode.classList.add("current-menu-ancestor");
      }

      if (document.body.classList.contains("single-exito")) {
        // Obtener el elemento que deseas modificar
        const elemento = document.getElementById("actualidadMegaMenu");

        // Agregar una clase al elemento
        elemento.parentNode.classList.add("current-menu-ancestor");
      }

      // Función para copiar la URL actual al hacer clic en un botón con la clase "copy-link"
      function copyCurrentURL() {
        // Obtén la URL actual del navegador
        const currentURL = window.location.href;

        // Crea un elemento de entrada de texto temporal
        const tempInput = document.createElement('input');
        tempInput.value = currentURL;

        // Agrega el elemento de entrada de texto al DOM, pero ocúltalo
        tempInput.style.position = 'absolute';
        tempInput.style.left = '-9999px';
        document.body.appendChild(tempInput);

        // Selecciona y copia el contenido del elemento de entrada de texto
        tempInput.select();
        document.execCommand('copy');

        // Elimina el elemento de entrada de texto temporal del DOM
        document.body.removeChild(tempInput);

        // Puedes mostrar un mensaje o realizar otras acciones después de copiar
        // alert('URL copiada al portapapeles: ' + currentURL);
      }

      // Obtén todos los botones con la clase "copy-link" y asigna un controlador de eventos a cada uno
      const copyButtons = document.querySelectorAll('.copy-link');
      
      if (copyButtons !== null) {
        copyButtons.forEach(function (button) {
          button.addEventListener('click', function (event) {
            // Evitar el comportamiento predeterminado del enlace
            event.preventDefault();
      
            // Acceder al elemento 'a' padre y agregar la clase 'active'
            const linkElement = event.currentTarget;
            linkElement.classList.add('active');
      
            // Eliminar la clase 'active' después de un tiempo (por ejemplo, 2 segundos)
            setTimeout(function () {
              linkElement.classList.remove('active');
            }, 1500); // 2000 milisegundos (2 segundos)
      
            // Agregar aquí la lógica para copiar la URL si es necesario
            copyCurrentURL();
          });
        });
      }

    });
  },
};
