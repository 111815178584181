import { Swiper } from '../../../../node_modules/swiper/swiper-bundle.esm.browser.js'

export default {
  init() {
    // JavaScript to be fired on the home page
    var swiperHeroThumbs = new Swiper('.js-swiper-hero-thumbs', {
      direction: 'vertical',
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      slidesPerView: 3,
      height: 280,
      history: false,
      autoplay: false,
      on: {
        'afterInit': function (swiper) {
          swiper.el.style.opacity = 1,
          swiper.el.querySelectorAll('.js-swiper-pagination-progress-body-helper').forEach($progress => $progress.style.transitionDuration = `100ms`)
        }
      }
    });

    var swiperHero = new Swiper('.js-swiper-hero',{
      speed: 1000,
      effect: 'fade',
      autoplay: false,
      loop: true,
      pagination: {
        el: '.js-swiper-hero-pagination',
        clickable: true,
      },
      thumbs: {
        swiper: swiperHeroThumbs
      }
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
