export default {
  init() {
    // JavaScript to be fired on the about us page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    const alphabeticalBtn = document.getElementById("alphabetical-btn");

    if ( alphabeticalBtn !== null ) {
      const lista = document.getElementById("alphabetical-gallery");
      const elementos = Array.from(lista.children);
      const select = document.getElementById("alphabeticalOrder");

      window.addEventListener('DOMContentLoaded', (event) => {
        elementos.sort((a, b) => {
          const idA = a.id.toUpperCase();
          const idB = b.id.toUpperCase();
          if (idA < idB) {
              return -1;
          }
          if (idA > idB) {
              return 1;
          }
          return 0;
        });

        lista.innerHTML = '';

        elementos.forEach((elemento) => {
          lista.appendChild(elemento);
        });
      });

      select.addEventListener("change", function () {
          const sortOrder = select.value;

          elementos.sort((a, b) => {
              const idA = a.getAttribute("id").toLowerCase();
              const idB = b.getAttribute("id").toLowerCase();

              if (sortOrder === "alphabeticalOrderSelectAZ") {
                  return idA.localeCompare(idB);
              } else if (sortOrder === "alphabeticalOrderSelectZA") {
                  return idB.localeCompare(idA);
              }
          });

          elementos.forEach((elemento) => lista.appendChild(elemento));
      });
    }
  }
};
