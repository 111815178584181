// Import everything from autoload
//import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import templateGenericTeam from './routes/template-generic-team';
import templateGenericServicePage from './routes/template-generic-service-page';
import templateGenericNews from './routes/template-generic-news';
import single from './routes/single';


/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  templateGenericTeam,
  templateGenericServicePage,
  templateGenericNews,
  single
});

// Load Events
routes.loadEvents();
