import FsLightbox from 'fslightbox';

export default {
  init() {
    // JavaScript to be fired on the about us page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    const allEntriesPostsContainer = document.getElementById('all-entries-posts-container');
    const filteredPostsContainer = document.getElementById('filtered-posts-news-container');

    // Pages Controllers
    document.addEventListener('DOMContentLoaded', function () {
      const pageSelect = document.getElementById('pagesNewsAGM');

      if ( pageSelect !== null )  {
        const homeURL = pageSelect.getAttribute('data-home-url');

        pageSelect.addEventListener('change', function () {
            if (pageSelect.value !== '') {
              const selectedPageID = pageSelect.value;
              const pageURL = `${homeURL}?page_id=${selectedPageID}`;
              window.location.href = pageURL;
            }
        });
      }
    });

    // Services Controllers
    document.addEventListener('DOMContentLoaded', function () {
      const taxonomySelect = document.getElementById('servicesNewsAGM');

      if ( taxonomySelect !== null && allEntriesPostsContainer !== null && filteredPostsContainer !== null )  {
        taxonomySelect.addEventListener('change', function () {
          const selectedTermId = taxonomySelect.value;
  
          if (selectedTermId) {
              document.getElementById("employeesNewsFilter").value = 'none';
              document.getElementById("datesAGM").value = 'none';

              // Realizar una solicitud AJAX para obtener los resultados por término
              const xhr = new XMLHttpRequest();
              xhr.open('GET', '/wp-admin/admin-ajax.php?action=get_posts_by_taxonomy&id=' + selectedTermId, true);
  
              xhr.onload = function () {
                  if (xhr.status === 200) {
                    allEntriesPostsContainer.classList.add("d-none");
                    filteredPostsContainer.innerHTML = xhr.responseText;

                    const clickableElements = document.querySelectorAll(`a[data-fslightbox^="youtube-video-ajax-"]`);
                    console.log('clickableElements', clickableElements);

                    clickableElements.forEach(function (clickableElement) {
                      clickableElement.addEventListener('click', function (event) {
                        event.preventDefault();
                        const lightbox = new FsLightbox();
                        lightbox.open();
                        console.log('clickableElement --------------->', clickableElement);
                      });
                    });

                  } else {
                    console.error('Error en la solicitud AJAX');
                  }
              };
  
              xhr.send();

          } else {
              // Manejar el caso cuando se selecciona la opción predeterminada "Seleccione un término"
              filteredPostsContainer.innerHTML = ''; // Borrar el contenido del contenedor
          }
        });
      }
    });

    // Authors News Controllers
    document.addEventListener('DOMContentLoaded', function() {
      const authorSelect = document.getElementById('employeesNewsFilter');

      if ( authorSelect !== null && allEntriesPostsContainer !== null && filteredPostsContainer !== null )  {
        authorSelect.addEventListener('change', function() {
          document.getElementById("servicesNewsAGM").value = 'none';
          document.getElementById("datesAGM").value = 'none';

          var selectedValue = this.value;
  
          var xhr = new XMLHttpRequest();
          xhr.open('POST', '/wp-admin/admin-ajax.php', true); // URL de administración de AJAX
          xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          xhr.onreadystatechange = function() {
              if (xhr.readyState === 4 && xhr.status === 200) {
                  allEntriesPostsContainer.classList.add("d-none");
                  filteredPostsContainer.innerHTML = xhr.responseText;
              }
          };
  
          var data = 'action=filter_posts_by_agm_author&selected_value=' + selectedValue;
          xhr.send(data);
        });
      }
    });

    // Dates Controllers
    document.addEventListener('DOMContentLoaded', function () {
      const monthSelect = document.getElementById('datesAGM');
  
      if (monthSelect !== null && allEntriesPostsContainer !== null && filteredPostsContainer !== null) {
          monthSelect.addEventListener('change', function () {
              const selectedOption = monthSelect.value;
  
              // Verificar si el valor seleccionado es "none"
              if (selectedOption === 'none') {
                  // Recargar la página actual
                  window.location.reload();
                  return; // Detener la ejecución de la función aquí
              }
  
              // Crear una instancia de XMLHttpRequest
              const xhr = new XMLHttpRequest();
  
              // Configurar la solicitud
              xhr.open('POST', '/wp-admin/admin-ajax.php', true);
              xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  
              // Definir la función de respuesta
              xhr.onload = function () {
                  if (xhr.status === 200) {
                      allEntriesPostsContainer.classList.add("d-none");
                      filteredPostsContainer.innerHTML = xhr.responseText;
                  } else {
                      console.error('Error en la solicitud AJAX');
                  }
              };
  
              // Enviar la solicitud con el valor seleccionado
              xhr.send('action=get_posts_by_month&month_year=' + selectedOption);
          });
      }
    });
  }
};
