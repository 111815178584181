export default {
  init() {
    // JavaScript to be fired on the about us page
  },
  finalize() {
    const accordionsItems = document.querySelectorAll(".accordion-team");
    const collapseElementList = [].slice.call(document.querySelectorAll('#all-team .collapse'));
    const employeeItems = document.querySelectorAll(".team-item");
    const closeAllBtn = document.getElementById("close-all-btn");
    const openAllBtn = document.getElementById("open-all-btn");
    const alphabeticalBtn = document.getElementById("alphabetical-btn");
    const radioOfficesAGM = document.querySelectorAll('.js-offices input[name="officesAGM"]');

    if ( accordionsItems !== null && employeeItems !== null && radioOfficesAGM !== null ) {
      const radioOfficesAGM = document.querySelectorAll('.js-offices input[name="officesAGM"]');

      // AGM Employee
      document.addEventListener('input', function (event) {

        if (event.target.id !== 'employeesFilter') return;

        // console.log('event.target.value => ', event.target.value);

        if (event.target.value !== 'none') {
          document.getElementById("servicesAGM").value = 'none';
          closeAllBtn.classList.add("d-none");
          openAllBtn.classList.add("d-none");

          let employeeElements = document.querySelectorAll('[data-title]');

          accordionsItems.forEach(accordionsItem => {
            accordionsItem.classList.add("d-none");
          }); 

          employeeItems.forEach(employeeItem => {
            let title = event.target.value;
            employeeItem.classList.add("d-none");
            employeeElements = document.querySelectorAll(`[data-title*="${title}"]`);
          });

          employeeElements.forEach(employeeElement => {
            employeeElement.classList.remove("d-none");
            employeeElement.closest('div.accordion-team').classList.remove("d-none");
            employeeElement.closest('div.accordion-collapse').classList.remove("collapsing");
            employeeElement.closest('div.accordion-collapse').classList.remove("collapse");
            employeeElement.closest('div.accordion-collapse').classList.add("show");
          });

          radioOfficesAGM.forEach((radio) => {
            radio.checked = false;
            radio.disabled = false;
          });

          // console.log('employeeElements =========>', employeeElements);
        }
        else
        {
          accordionsItems.forEach(accordionsItem => {
            accordionsItem.classList.remove("d-none");
          });
          employeeItems.forEach(employeeItem => {
            employeeItem.classList.remove("d-none");
          });
        }
      }, false);
      
      // AGM Service
      document.addEventListener('input', function (event) {

        // Only run for #servicesAGM select
        if (event.target.id !== 'servicesAGM') return;
      
        // console.log('event.target.value', event.target.value);

        if (event.target.value !== 'none') { 
          document.getElementById("employeesFilter").value = 'none';
          
          accordionsItems.forEach(accordionsItem => {
            accordionsItem.classList.add("d-none");
          });  
          
          let accordionElement = document.getElementById(event.target.value);
          
          if ( accordionElement !== null ) {
            accordionElement.classList.remove("d-none");
            closeAllBtn.classList.add("d-none");
            openAllBtn.classList.add("d-none");
            alphabeticalBtn.classList.add("d-none");
            employeeItems.forEach(employeeItem => { 
              employeeItem.classList.remove("d-none");
            });
            accordionElement.getElementsByClassName( 'accordion-button' )[0].classList.remove("collapsed");
            accordionElement.getElementsByClassName( 'accordion-collapse' )[0].classList.remove("collapse");
            accordionElement.getElementsByClassName( 'accordion-collapse' )[0].classList.add("show");
            accordionElement.getElementsByClassName( 'accordion-collapse' )[0].classList.remove("collapsing");

            radioOfficesAGM.forEach((radio) => {
              radio.checked = false;
              radio.disabled = false;
            });
          }
        } 
        // no tenemos ningún valor
        else {
          // console.log('accordionsItems', accordionsItems);
          accordionsItems.forEach(accordionsItem => {
            accordionsItem.classList.remove("d-none");
          });
          employeeItems.forEach(employeeItem => {
            employeeItem.classList.remove("d-none");
          });
          closeAllBtn.classList.remove("d-none");
          alphabeticalBtn.classList.remove("d-none");
        }
      }, false);

      // Offices Filters
      const OfficesFilter = function () {
        this.offices = Array.from(document.querySelectorAll('[name="officesAGM"]'));

        this.filters = {
          offices: [],
        };

        // console.log('this.offices', this.offices);

        this._bindEventListeners();
      };

      /**
      * Bind event listeners for when the filters change.
      */
      OfficesFilter.prototype._bindEventListeners = function () {
        this._onOfficeChange = this._handleOfficeChange.bind(this);

        this.offices.forEach(function (input) {
          input.addEventListener('click', this._onOfficeChange);
          // console.log('----------------> ha cambiado algo en el radio button <-------------------------------------------------------');
          // console.log('----------------> input.value <-------------------------------------------------------', input.value + ' ' + input.checked);
        }, this);
      };

      
      /**
      * Get the values of each checked input.
      * @return {Array.<string>}
      */
      OfficesFilter.prototype._getCurrentOfficeFilters = function () {
        return this.offices.filter(function (input) {
          // console.log('input', input.value + ' ' + input.checked);
          return input.checked;
        }).map(function (input) {
          // console.log('input.value', input.value);
          return input.value;
        });
      };

      /**
      * A office input check state changed, update the current filters and filter
      */
      OfficesFilter.prototype._handleOfficeChange = function (event) {
        const clickedInput = event.target;
        const clickedValue = clickedInput.value;
      
        if (clickedInput.checked) {
          // El botón de radio se ha marcado, verificamos si ya estaba marcado previamente
          if (this.filters.offices.length === 1 && this.filters.offices[0] === clickedValue) {
            // Si estaba marcado previamente y se hizo clic en él nuevamente, limpiamos el array
            clickedInput.checked = false;
            this.filters.offices = [];
          } else {
            // Si no estaba marcado previamente o se hizo clic en otro radio button, actualizamos el array de filtros
            this.filters.offices = [clickedValue];
          }
        } else {
          // El botón de radio se ha desmarcado, lo eliminamos del array de filtros
          const index = this.filters.offices.indexOf(clickedValue);
          if (index !== -1) {
            this.filters.offices.splice(index, 1);
          }
        }
      
        // console.log('this.filters.offices ////////////////////////////////////>>>>', this.filters.offices);
      
        // Llamamos al método filter
        this.filter();
      };           

      /**
      * Filter shuffle based on the current state of filters.
      */
      OfficesFilter.prototype.filter = function () {
        const equalsCheck = (a, b) => {
          return JSON.stringify(a) === JSON.stringify(b);
        }

        const commonValuesCheck = (array1, array2) => {
          for (let i = 0; i < array1.length; i++) {
            for (let j = 0; j < array2.length; j++) {
              if (array1[i] === array2[j]) {
                return true; // Se encontró un valor en común
              }
            }
          }
          return false; // No se encontraron valores en común
        }

        if (this.hasActiveFilters()) {
          document.getElementById("employeesFilter").value = 'none';

          const activeOffices = this.filters.offices;
          // console.log('activeOffices', activeOffices);
          employeeItems.forEach(employeeItem => {
            const employeesDataValues = JSON.parse(JSON.stringify((employeeItem).dataset.offices));
            let employeesDataValuesArray = employeesDataValues.split(',');
            // console.log('activeOffices', activeOffices);
            // console.log('employeesDataValuesArray', employeesDataValuesArray);
            let commonOffices = employeesDataValuesArray.filter(i => activeOffices.includes(i));
            // console.log('commonOffices', commonOffices);

            employeeItem.classList.add("d-none");
            // Comparing the arrays
            if (commonValuesCheck(commonOffices, employeesDataValuesArray)) {
              employeeItem.classList.remove("d-none");
            } else {
              //let title = employeeItem.dataset.title;
              // console.log('Todos los empleados que se deben ocultar', title);
            }

          });          
        } else {
          employeeItems.forEach(employeeItem => {
            employeeItem.classList.remove("d-none");
          });
        }
      };

      /**
      * If any of the arrays in the `filters` property have a length of more than zero,
      * that means there is an active filter.
      * @return {boolean}
      */
      OfficesFilter.prototype.hasActiveFilters = function () {
        return Object.keys(this.filters).some(function (key) {
          return this.filters[key].length > 0;
        }, this);
      };

      /**
      * Determine whether an element passes the current filters.
      * @param {Element} element Element to test.
      * @return {boolean} Whether it satisfies all current filters.
      */
      OfficesFilter.prototype.itemPassesFilters = function (element) {
        const offices = this.filters.offices;
        const office = element.getAttribute('data-group');

        // If there are active shape filters and this shape is not in that array.
        if (offices.length > 0 && !offices.includes(office)) {
          return false;
        }

        return true;
      };

      document.addEventListener('DOMContentLoaded', function () {
        window.officesFilter = new OfficesFilter(document.querySelector('.js-offices'));
      });    
    }

    if ( accordionsItems !== null && closeAllBtn !== null )  {
      // console.log('collapseElementList =>', collapseElementList);
      closeAllBtn.style.cursor = "pointer"; 
      closeAllBtn.addEventListener('click', function () {
        this.classList.add("d-none");
        openAllBtn.classList.remove("d-none");
        collapseElementList.forEach(collapseElement => {
          collapseElement.classList.add("collapsing");
          collapseElement.classList.remove("show");
          collapseElement.previousElementSibling.getElementsByClassName( 'accordion-button' )[0].classList.add("collapsed");
        });
      });
    }

    if ( accordionsItems !== null && openAllBtn !== null )  {
      openAllBtn.style.cursor = "pointer"; 
      openAllBtn.addEventListener('click', function () {
        this.classList.add("d-none");
        closeAllBtn.classList.remove("d-none");
        collapseElementList.forEach(collapseElement => {
          collapseElement.classList.remove("collapsing");
          collapseElement.classList.add("show");
          collapseElement.previousElementSibling.getElementsByClassName( 'accordion-button' )[0].classList.remove("collapsed");
        });
      });
    }

    if ( alphabeticalBtn !== null ) {
      const lista = document.getElementById("alphabetical-gallery");
      const elementos = Array.from(lista.children);
      const select = document.getElementById("alphabeticalOrder");

      window.addEventListener('DOMContentLoaded', (event) => {
        elementos.sort((a, b) => {
          const idA = a.id.toUpperCase();
          const idB = b.id.toUpperCase();
          if (idA < idB) {
              return -1;
          }
          if (idA > idB) {
              return 1;
          }
          return 0;
        });

        lista.innerHTML = '';

        elementos.forEach((elemento) => {
          lista.appendChild(elemento);
        });
      });

      select.addEventListener("change", function () {
          const sortOrder = select.value;

          elementos.sort((a, b) => {
              const idA = a.getAttribute("id").toLowerCase();
              const idB = b.getAttribute("id").toLowerCase();

              if (sortOrder === "alphabeticalOrderSelectAZ") {
                  return idA.localeCompare(idB);
              } else if (sortOrder === "alphabeticalOrderSelectZA") {
                  return idB.localeCompare(idA);
              }
          });

          elementos.forEach((elemento) => lista.appendChild(elemento));

          if ( radioOfficesAGM != null ) {
            radioOfficesAGM.forEach((radio) => {
              radio.checked = false;
              radio.disabled = false;
            });
          }
      });
    }
  }
};
